<template>
  <div>
    <taskToday3 v-if="$root.profile.role !== 11000" />
    <taskToday v-else />
  </div>
</template>

<script>
export default {
  components: {
    taskToday: () => import("./w/tasksToday.vue"),
    taskToday2: () => import("./w/tasksToday2.vue"),
    taskToday3: () => import("./w/tasksToday3.vue"),
  },
  data() {
    return {};
  },
  created() {
    this.$root.title = "Главная";
  },
  methods: {},
};
</script>
